import React, { useState } from 'react';
import Logo from '@/assets/logo_white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

export default function LayoutMain({ children }) {
  const [getMobileNavActive, setMovbileNavActive] = useState(true);
  return (
    <div className="min-h-screen p-4 bg-black lg:flex lg:flex-row">
      {!getMobileNavActive && (
        <button
          type="button"
          className="text-white lg:hidden "
          onClick={() => setMovbileNavActive(!getMobileNavActive)}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      )}
      {getMobileNavActive && (
        <aside className="h-screen text-white bg-black sm:absolute sm:top-0 sm:bottom-0 sm:left-0 sm:right-0 sm:z-10 lg:p-8 lg:relative lg:basis-2/12 drop-shadow-lg lg:h-auto ">
          <div className="mb-5">
            <button
              type="button"
              onClick={() => setMovbileNavActive(!getMobileNavActive)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <div className="text-center lg:sticky lg:top-12">
            <a href="/" className="block pt-1 pb-8">
              <Logo />
            </a>

            <nav className="-ml-6">
              <ul className="mb-8">
                <li className="mb-2">
                  <Link className="block hover:text-gray-400" to="/overview">
                    Visions
                  </Link>
                </li>

                <li className="mb-2">
                  <Link className="block hover:text-gray-400" to="/overview">
                    Misc.
                  </Link>
                </li>

                <li className="mb-2">
                  <Link className="block hover:text-gray-400" to="/overview">
                    Visions
                  </Link>
                </li>
              </ul>

              <ul className="mb-8">
                <li className="mb-2">
                  <Link className="block hover:text-gray-400" to="/about">
                    About
                  </Link>
                </li>

                <li className="mb-2">
                  <Link className="block hover:text-gray-400" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
      )}

      <main
        className="lg:p-8 lg:basis-10/12"
        style={{ background: `hsl(0deg 0% 10%)` }}
      >
        <div className="m-auto lg:w-3/4">{children}</div>
      </main>
    </div>
  );
}
